import React, { useEffect } from "react";
import { BasicTemplate } from "@templates/BasicTemplate";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { ReactComponent as Svg } from "@vectors/mail-sent.svg";
import { breakpoints } from "@theme/breakpoints";
import { Button } from "@components/atoms/Button";
import { Routes } from "@routes";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { useLocation } from "@reach/router";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 90%;
  max-width: 1620px;
  margin: 200px auto 0;

  ${breakpoints.phoneMax`
    margin-top: 150px;
  `}
`;

export const StyledSvg = styled(Svg)`
  width: 80%;
  max-width: 200px;
  height: auto;

  ${breakpoints.laptopMax`
    max-width: 150px;
  `};

  ${breakpoints.phoneMax`
    max-width: 130px;
  `};
`;

export const StyledHeadline = styled.h1`
  font-size: 4.2rem;
  margin: 60px 0 0;

  ${breakpoints.laptopMax`
   font-size: 3.6rem;
    margin: 40px 0 0;
  `};

  ${breakpoints.tabletMax`
    max-width: 600px;
  `};

  ${breakpoints.phoneMax`
    font-size: 2.4rem;
    max-width: 400px;
  `};
`;

export const StyledParagraph = styled.p`
  line-height: 2;
  font-size: 2rem;

  ${breakpoints.laptopMax`
     font-size: 1.8rem;
  `};

  ${breakpoints.phoneMax`
    font-size: 1.6rem;
  `};
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  gap: 40px;
  margin: 20px 0 0;

  ${breakpoints.laptopMax`
    gap: 10px;
  `};

  ${breakpoints.phoneMax`
    flex-direction: column;
    align-items: center;
    margin: 10px 0 0;
    gap: 15px;
  `};
`;

export default () => {
  const { state } = useLocation();
  const { t } = useTranslation("thank-you");
  const { navigate } = useI18next();

  useEffect(() => {
    if (!(state as any)?.fromForm) {
      navigate(Routes.Home);
    } else {
      window.history.pushState({}, "");
    }
  }, []);

  return (
    <BasicTemplate title="" description="" withoutNewsletter>
      <StyledWrapper>
        <StyledSvg />
        <StyledHeadline>{t("title")}</StyledHeadline>
        <StyledParagraph dangerouslySetInnerHTML={{ __html: t("content") }} />
        <StyledButtonsWrapper>
          <Button secondary as={Link as any} to={Routes.Home}>
            {t("homeBtn")}
          </Button>
          <Button secondary as={Link as any} to={Routes.Blog}>
            {t("blogBtn")}
          </Button>
        </StyledButtonsWrapper>
      </StyledWrapper>
    </BasicTemplate>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
